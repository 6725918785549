import BaseModel, { EntityType } from "./BaseModel"

import { BOIRSubmission as BOIRSubmissionInterface } from "~/interfaces/api/BOIRSubmission.interface"

export default class BOIRSubmission extends BaseModel implements BOIRSubmissionInterface {
  static entity = EntityType.BOIRSubmission

  static fields() {
    return {
      id: this.number(0),
      resource_type: this.string(""),
      created_at: this.attr(""),
      updated_at: this.attr(""),
      submitted_at: this.attr(""),
      completed_at: this.attr(""),
      released_at: this.attr(""),
      status: this.string(""),
      xml: this.string(""),
      translations: this.attr(""),
      submission_errors: this.attr(""),
      order_id: this.number(0)
    }
  }
}
