import BaseModel, { EntityType } from './BaseModel'
import Product from './Product'
import { Category as CategoryInterface } from '~/interfaces/api/Categories.interface'

export default class Category extends BaseModel implements CategoryInterface {
  static entity = EntityType.categories

  static fields() {
    return {
      id: this.number(0),
      resource_type: this.string(''),
      created_at: this.attr(''),
      updated_at: this.attr(''),
      name: this.string(''),
      slug: this.string(''),
      description: this.string(''),
      short_description: this.string(''),
      is_active: this.boolean(false),
      active_products_count: this.number(0),
      products: this.hasMany(Product, 'category_id'),
    }
  }

  // id!: number
  // resource_type!: string
  // created_at!: Date
  // updated_at!: Date
  // name!: string
  // slug!: string
  // description!: string
  // short_description?: any
  // is_active!: boolean
  // active_products_count!: number
}
