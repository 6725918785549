import BaseModel, { EntityType } from './BaseModel'
import User from './User'

import { RecurlyError as RecurlyErrorInterface } from '~/interfaces/api/RecurlyErrors.interface'

export default class RecurlyError
  extends BaseModel
  implements RecurlyErrorInterface
{
  static entity = EntityType.recurlyerrors

  static fields() {
    return {
      id: this.number(0),
      created_at: this.attr(''),
      user_id: this.number(0),
      user: this.belongsTo(User, 'user_id'),
      category: this.attr(''),
      code: this.attr(''),
      merchant_advice: this.attr(''),
      transaction_id: this.attr(''),
    }
  }

  id: number
  created_at: Date
  user_id: number
  category: string
  code: string
  merchant_advice: string
  transaction_id: string
}
