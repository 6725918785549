import BaseModel, { EntityType } from "./BaseModel"

import { Task as TaskInterface } from "~/interfaces/api/Task.interface"

export default class Task extends BaseModel implements TaskInterface {
  static entity = EntityType.task

  static fields() {
    return {
      id: this.number(0),
      created_at: this.string(""),
      updated_at: this.string(""),
      due_date: this.string(""),
      canceled_at: this.string(""),
      completed_at: this.string(""),
      claimed_at: this.string(""),
      admin_handle: this.string(""),
      title: this.string(""),
      description: this.string(""),
      admin_id: this.number(0),
      team_id: this.number(0),
      is_blocked: this.attr(""),
      status: this.string(""),
      resource_type: this.string(""),
      primary_record_type: this.string(""),
      primary_record_id: this.number(0),
      tasks_blocking_this: this.attr(""),
      manually_blocked: this.attr(""),
      blocks: this.attr(""),
      template: this.attr(""),
      tasks_blocked_by_this: this.attr(""),
      manual_blockers: this.attr(""),
      labels_assignments: this.attr("")
    }
  }
}
