import BaseModel, { EntityType } from './BaseModel'

import { ComplianceAmendment as ComplianceAmendmentInterface } from '~/interfaces/api/Companies.interface'

export default class ComplianceAmendment
  extends BaseModel
  implements ComplianceAmendmentInterface
{
  static entity = EntityType.complianceAmendment

  static fields() {
    return {
      id: this.number(0),
      created_at: this.attr(''),
      updated_at: this.attr(''),
      compliance_guard_id: this.attr(''),
      used_at: this.attr(''),
      notes: this.attr(''),
    }
  }
}
