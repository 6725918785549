import BaseModel, { EntityType } from './BaseModel'
import User from './User'

import { Charge as ChargeInterface } from '~/interfaces/api/Charges.interface'

export default class Charge extends BaseModel implements ChargeInterface {
  static entity = EntityType.charges

  static state() {
    return {
      total: null,
    }
  }

  static primaryKey = 'id'

  static fields() {
    return {
      id: this.number(0),
      name: this.string(''),
      amount: this.number(0),
      amount_refunded: this.number(0),
      amount_to_refund: this.number(0),
      refunded_at: this.string(''),
      recurly_id: this.string(''),
      created_at: this.attr(''),
      updated_at: this.attr(''),
      user_id: this.number(0),
      company_id: this.number(0),
      user: this.belongsTo(User, 'user_id'),
      refunds: this.attr(''),
      chargebacks: this.attr(''),
      line_items: this.attr(''),
      last_four: this.attr(''),
      card_type: this.attr(''),
      chargeable_type: this.attr(''),
      chargeable_id: this.attr(''),
      refundable: this.attr(''),
      billing_info_object: this.attr(''),
    }
  }

  // id: number
  // name: string
  // amount: number
  // amount_refunded: number
  // amount_to_refund: number
  // refunded_at: string
  // recurly_id: string
  // created_at: Date
  // updated_at: Date
  // user_id: number
  // refunds: Array<{
  //   id: number
  //   admin_id: number
  //   amount_to_refund: number
  //   amount_refunded: number
  //   refunded_at: Date
  //   created_at: Date
  //   state: string
  // }>

  // chargebacks: Array<{
  //   id: number
  //   created_at: Date
  // }>

  // line_items: Array<{
  //   cost: number
  //   desc: string
  //   editable: Boolean
  //   editable_value: string
  //   field_name: string
  //   free_type: string
  //   non_revenue_cost: number
  //   order_slugs: string
  // }>

  // last_four: string
  // card_type: string
  // chargeable_type: string
  // chargeable_id: string
  // refundable: boolean

  get amount_formatted() {
    return '$' + this.amount.toFixed(2)
  }
}
