import BaseModel, { EntityType } from './BaseModel'

import { Label as LabelInterface } from '~/interfaces/api/Label.interface'

export default class Label extends BaseModel implements LabelInterface {
  static entity = EntityType.labels

  static fields() {
    return {
      id: this.number(0),
      name: this.string(''),
      color: this.string(''),
    }
  }
}
