import { default as _403I8scx7QIboMeta } from "/home/app/pages/403.vue?macro=true";
import { default as butter_pagesHxt6IUf1KLMeta } from "/home/app/pages/admin/butter_pages.vue?macro=true";
import { default as cdrn_search0mn8mLyBd6Meta } from "/home/app/pages/admin/cdrn_search.vue?macro=true";
import { default as permissionsDohehLCoygMeta } from "/home/app/pages/admin/permissions.vue?macro=true";
import { default as plansB07h5rRjzhMeta } from "/home/app/pages/admin/plans.vue?macro=true";
import { default as state_infoQQkGSIKTwcMeta } from "/home/app/pages/admin/state_info.vue?macro=true";
import { default as stripe_alertsVkcqVpNpfyMeta } from "/home/app/pages/admin/stripe_alerts.vue?macro=true";
import { default as task_recipesKAaE0STa1fMeta } from "/home/app/pages/admin/task_recipes.vue?macro=true";
import { default as teamsno8iryyUi9Meta } from "/home/app/pages/admin/teams.vue?macro=true";
import { default as indexQUngJQTBdtMeta } from "/home/app/pages/chargebacks/[slug]/index.vue?macro=true";
import { default as indexA6D3huBdxYMeta } from "/home/app/pages/chargebacks/index.vue?macro=true";
import { default as indexcdS4BkrPVlMeta } from "/home/app/pages/charges/[slug]/index.vue?macro=true";
import { default as indexrrZuDsPHHeMeta } from "/home/app/pages/companies/[slug]/index.vue?macro=true";
import { default as indexYxOH8PN4Y2Meta } from "/home/app/pages/experiments/index.vue?macro=true";
import { default as indexlhh3BLG4MVMeta } from "/home/app/pages/index.vue?macro=true";
import { default as indexypK2ALCUOuMeta } from "/home/app/pages/login/index.vue?macro=true";
import { default as indexNjN1ifz8itMeta } from "/home/app/pages/notifications/index.vue?macro=true";
import { default as indexrbSAIKBNnxMeta } from "/home/app/pages/orders/[slug]/index.vue?macro=true";
import { default as indexVkox4RRn27Meta } from "/home/app/pages/orders/compliance/index.vue?macro=true";
import { default as indexgszMaFAT6cMeta } from "/home/app/pages/orders/compliancefilings/index.vue?macro=true";
import { default as indexG6RVav5WqKMeta } from "/home/app/pages/orders/index.vue?macro=true";
import { default as indexiQmvb6RPz5Meta } from "/home/app/pages/products/[slug]/forms/[form]/index.vue?macro=true";
import { default as indexxtnpakuWF3Meta } from "/home/app/pages/products/[slug]/index.vue?macro=true";
import { default as indexL5PG0VRReAMeta } from "/home/app/pages/products/[slug]/payloads/[payload]/index.vue?macro=true";
import { default as indexciBtlXhTgeMeta } from "/home/app/pages/products/index.vue?macro=true";
import { default as indexP02dovEMwmMeta } from "/home/app/pages/stats/[slug]/index.vue?macro=true";
import { default as indexSRU8qNnq1uMeta } from "/home/app/pages/stats/index.vue?macro=true";
import { default as indexrWGgR5ehIYMeta } from "/home/app/pages/subscriptions/[slug]/index.vue?macro=true";
import { default as index400dxCV66iMeta } from "/home/app/pages/task_recipe/[slug]/index.vue?macro=true";
import { default as indexvAeuRFDKyXMeta } from "/home/app/pages/tasks/[slug]/index.vue?macro=true";
import { default as indexw11IWdxIO5Meta } from "/home/app/pages/tasks/index.vue?macro=true";
import { default as index0D2v02tQEyMeta } from "/home/app/pages/users/[slug]/index.vue?macro=true";
import { default as indexsn4vQIwrQrMeta } from "/home/app/pages/workstation/[slug]/index.vue?macro=true";
import { default as indexvxX2bSdgwzMeta } from "/home/app/pages/workstation/index.vue?macro=true";
import { default as indexHctLeKsi8QMeta } from "/home/app/pages/zendesk/index.vue?macro=true";
export default [
  {
    name: _403I8scx7QIboMeta?.name ?? "403",
    path: _403I8scx7QIboMeta?.path ?? "/403",
    meta: _403I8scx7QIboMeta || {},
    alias: _403I8scx7QIboMeta?.alias || [],
    redirect: _403I8scx7QIboMeta?.redirect || undefined,
    component: () => import("/home/app/pages/403.vue").then(m => m.default || m)
  },
  {
    name: butter_pagesHxt6IUf1KLMeta?.name ?? "admin-butter_pages",
    path: butter_pagesHxt6IUf1KLMeta?.path ?? "/admin/butter_pages",
    meta: butter_pagesHxt6IUf1KLMeta || {},
    alias: butter_pagesHxt6IUf1KLMeta?.alias || [],
    redirect: butter_pagesHxt6IUf1KLMeta?.redirect || undefined,
    component: () => import("/home/app/pages/admin/butter_pages.vue").then(m => m.default || m)
  },
  {
    name: cdrn_search0mn8mLyBd6Meta?.name ?? "admin-cdrn_search",
    path: cdrn_search0mn8mLyBd6Meta?.path ?? "/admin/cdrn_search",
    meta: cdrn_search0mn8mLyBd6Meta || {},
    alias: cdrn_search0mn8mLyBd6Meta?.alias || [],
    redirect: cdrn_search0mn8mLyBd6Meta?.redirect || undefined,
    component: () => import("/home/app/pages/admin/cdrn_search.vue").then(m => m.default || m)
  },
  {
    name: permissionsDohehLCoygMeta?.name ?? "admin-permissions",
    path: permissionsDohehLCoygMeta?.path ?? "/admin/permissions",
    meta: permissionsDohehLCoygMeta || {},
    alias: permissionsDohehLCoygMeta?.alias || [],
    redirect: permissionsDohehLCoygMeta?.redirect || undefined,
    component: () => import("/home/app/pages/admin/permissions.vue").then(m => m.default || m)
  },
  {
    name: plansB07h5rRjzhMeta?.name ?? "admin-plans",
    path: plansB07h5rRjzhMeta?.path ?? "/admin/plans",
    meta: plansB07h5rRjzhMeta || {},
    alias: plansB07h5rRjzhMeta?.alias || [],
    redirect: plansB07h5rRjzhMeta?.redirect || undefined,
    component: () => import("/home/app/pages/admin/plans.vue").then(m => m.default || m)
  },
  {
    name: state_infoQQkGSIKTwcMeta?.name ?? "admin-state_info",
    path: state_infoQQkGSIKTwcMeta?.path ?? "/admin/state_info",
    meta: state_infoQQkGSIKTwcMeta || {},
    alias: state_infoQQkGSIKTwcMeta?.alias || [],
    redirect: state_infoQQkGSIKTwcMeta?.redirect || undefined,
    component: () => import("/home/app/pages/admin/state_info.vue").then(m => m.default || m)
  },
  {
    name: stripe_alertsVkcqVpNpfyMeta?.name ?? "admin-stripe_alerts",
    path: stripe_alertsVkcqVpNpfyMeta?.path ?? "/admin/stripe_alerts",
    meta: stripe_alertsVkcqVpNpfyMeta || {},
    alias: stripe_alertsVkcqVpNpfyMeta?.alias || [],
    redirect: stripe_alertsVkcqVpNpfyMeta?.redirect || undefined,
    component: () => import("/home/app/pages/admin/stripe_alerts.vue").then(m => m.default || m)
  },
  {
    name: task_recipesKAaE0STa1fMeta?.name ?? "admin-task_recipes",
    path: task_recipesKAaE0STa1fMeta?.path ?? "/admin/task_recipes",
    meta: task_recipesKAaE0STa1fMeta || {},
    alias: task_recipesKAaE0STa1fMeta?.alias || [],
    redirect: task_recipesKAaE0STa1fMeta?.redirect || undefined,
    component: () => import("/home/app/pages/admin/task_recipes.vue").then(m => m.default || m)
  },
  {
    name: teamsno8iryyUi9Meta?.name ?? "admin-teams",
    path: teamsno8iryyUi9Meta?.path ?? "/admin/teams",
    meta: teamsno8iryyUi9Meta || {},
    alias: teamsno8iryyUi9Meta?.alias || [],
    redirect: teamsno8iryyUi9Meta?.redirect || undefined,
    component: () => import("/home/app/pages/admin/teams.vue").then(m => m.default || m)
  },
  {
    name: indexQUngJQTBdtMeta?.name ?? "chargebacks-slug",
    path: indexQUngJQTBdtMeta?.path ?? "/chargebacks/:slug()",
    meta: indexQUngJQTBdtMeta || {},
    alias: indexQUngJQTBdtMeta?.alias || [],
    redirect: indexQUngJQTBdtMeta?.redirect || undefined,
    component: () => import("/home/app/pages/chargebacks/[slug]/index.vue").then(m => m.default || m)
  },
  {
    name: indexA6D3huBdxYMeta?.name ?? "chargebacks",
    path: indexA6D3huBdxYMeta?.path ?? "/chargebacks",
    meta: indexA6D3huBdxYMeta || {},
    alias: indexA6D3huBdxYMeta?.alias || [],
    redirect: indexA6D3huBdxYMeta?.redirect || undefined,
    component: () => import("/home/app/pages/chargebacks/index.vue").then(m => m.default || m)
  },
  {
    name: indexcdS4BkrPVlMeta?.name ?? "charges-slug",
    path: indexcdS4BkrPVlMeta?.path ?? "/charges/:slug()",
    meta: indexcdS4BkrPVlMeta || {},
    alias: indexcdS4BkrPVlMeta?.alias || [],
    redirect: indexcdS4BkrPVlMeta?.redirect || undefined,
    component: () => import("/home/app/pages/charges/[slug]/index.vue").then(m => m.default || m)
  },
  {
    name: indexrrZuDsPHHeMeta?.name ?? "companies-slug",
    path: indexrrZuDsPHHeMeta?.path ?? "/companies/:slug()",
    meta: indexrrZuDsPHHeMeta || {},
    alias: indexrrZuDsPHHeMeta?.alias || [],
    redirect: indexrrZuDsPHHeMeta?.redirect || undefined,
    component: () => import("/home/app/pages/companies/[slug]/index.vue").then(m => m.default || m)
  },
  {
    name: indexYxOH8PN4Y2Meta?.name ?? "experiments",
    path: indexYxOH8PN4Y2Meta?.path ?? "/experiments",
    meta: indexYxOH8PN4Y2Meta || {},
    alias: indexYxOH8PN4Y2Meta?.alias || [],
    redirect: indexYxOH8PN4Y2Meta?.redirect || undefined,
    component: () => import("/home/app/pages/experiments/index.vue").then(m => m.default || m)
  },
  {
    name: indexlhh3BLG4MVMeta?.name ?? "index",
    path: indexlhh3BLG4MVMeta?.path ?? "/",
    meta: indexlhh3BLG4MVMeta || {},
    alias: indexlhh3BLG4MVMeta?.alias || [],
    redirect: indexlhh3BLG4MVMeta?.redirect || undefined,
    component: () => import("/home/app/pages/index.vue").then(m => m.default || m)
  },
  {
    name: indexypK2ALCUOuMeta?.name ?? "login",
    path: indexypK2ALCUOuMeta?.path ?? "/login",
    meta: indexypK2ALCUOuMeta || {},
    alias: indexypK2ALCUOuMeta?.alias || [],
    redirect: indexypK2ALCUOuMeta?.redirect || undefined,
    component: () => import("/home/app/pages/login/index.vue").then(m => m.default || m)
  },
  {
    name: indexNjN1ifz8itMeta?.name ?? "notifications",
    path: indexNjN1ifz8itMeta?.path ?? "/notifications",
    meta: indexNjN1ifz8itMeta || {},
    alias: indexNjN1ifz8itMeta?.alias || [],
    redirect: indexNjN1ifz8itMeta?.redirect || undefined,
    component: () => import("/home/app/pages/notifications/index.vue").then(m => m.default || m)
  },
  {
    name: indexrbSAIKBNnxMeta?.name ?? "orders-slug",
    path: indexrbSAIKBNnxMeta?.path ?? "/orders/:slug()",
    meta: indexrbSAIKBNnxMeta || {},
    alias: indexrbSAIKBNnxMeta?.alias || [],
    redirect: indexrbSAIKBNnxMeta?.redirect || undefined,
    component: () => import("/home/app/pages/orders/[slug]/index.vue").then(m => m.default || m)
  },
  {
    name: indexVkox4RRn27Meta?.name ?? "orders-compliance",
    path: indexVkox4RRn27Meta?.path ?? "/orders/compliance",
    meta: indexVkox4RRn27Meta || {},
    alias: indexVkox4RRn27Meta?.alias || [],
    redirect: indexVkox4RRn27Meta?.redirect || undefined,
    component: () => import("/home/app/pages/orders/compliance/index.vue").then(m => m.default || m)
  },
  {
    name: indexgszMaFAT6cMeta?.name ?? "orders-compliancefilings",
    path: indexgszMaFAT6cMeta?.path ?? "/orders/compliancefilings",
    meta: indexgszMaFAT6cMeta || {},
    alias: indexgszMaFAT6cMeta?.alias || [],
    redirect: indexgszMaFAT6cMeta?.redirect || undefined,
    component: () => import("/home/app/pages/orders/compliancefilings/index.vue").then(m => m.default || m)
  },
  {
    name: indexG6RVav5WqKMeta?.name ?? "orders",
    path: indexG6RVav5WqKMeta?.path ?? "/orders",
    meta: indexG6RVav5WqKMeta || {},
    alias: indexG6RVav5WqKMeta?.alias || [],
    redirect: indexG6RVav5WqKMeta?.redirect || undefined,
    component: () => import("/home/app/pages/orders/index.vue").then(m => m.default || m)
  },
  {
    name: indexiQmvb6RPz5Meta?.name ?? "products-slug-forms-form",
    path: indexiQmvb6RPz5Meta?.path ?? "/products/:slug()/forms/:form()",
    meta: indexiQmvb6RPz5Meta || {},
    alias: indexiQmvb6RPz5Meta?.alias || [],
    redirect: indexiQmvb6RPz5Meta?.redirect || undefined,
    component: () => import("/home/app/pages/products/[slug]/forms/[form]/index.vue").then(m => m.default || m)
  },
  {
    name: indexxtnpakuWF3Meta?.name ?? "products-slug",
    path: indexxtnpakuWF3Meta?.path ?? "/products/:slug()",
    meta: indexxtnpakuWF3Meta || {},
    alias: indexxtnpakuWF3Meta?.alias || [],
    redirect: indexxtnpakuWF3Meta?.redirect || undefined,
    component: () => import("/home/app/pages/products/[slug]/index.vue").then(m => m.default || m)
  },
  {
    name: indexL5PG0VRReAMeta?.name ?? "products-slug-payloads-payload",
    path: indexL5PG0VRReAMeta?.path ?? "/products/:slug()/payloads/:payload()",
    meta: indexL5PG0VRReAMeta || {},
    alias: indexL5PG0VRReAMeta?.alias || [],
    redirect: indexL5PG0VRReAMeta?.redirect || undefined,
    component: () => import("/home/app/pages/products/[slug]/payloads/[payload]/index.vue").then(m => m.default || m)
  },
  {
    name: indexciBtlXhTgeMeta?.name ?? "products",
    path: indexciBtlXhTgeMeta?.path ?? "/products",
    meta: indexciBtlXhTgeMeta || {},
    alias: indexciBtlXhTgeMeta?.alias || [],
    redirect: indexciBtlXhTgeMeta?.redirect || undefined,
    component: () => import("/home/app/pages/products/index.vue").then(m => m.default || m)
  },
  {
    name: indexP02dovEMwmMeta?.name ?? "stats-slug",
    path: indexP02dovEMwmMeta?.path ?? "/stats/:slug()",
    meta: indexP02dovEMwmMeta || {},
    alias: indexP02dovEMwmMeta?.alias || [],
    redirect: indexP02dovEMwmMeta?.redirect || undefined,
    component: () => import("/home/app/pages/stats/[slug]/index.vue").then(m => m.default || m)
  },
  {
    name: indexSRU8qNnq1uMeta?.name ?? "stats",
    path: indexSRU8qNnq1uMeta?.path ?? "/stats",
    meta: indexSRU8qNnq1uMeta || {},
    alias: indexSRU8qNnq1uMeta?.alias || [],
    redirect: indexSRU8qNnq1uMeta?.redirect || undefined,
    component: () => import("/home/app/pages/stats/index.vue").then(m => m.default || m)
  },
  {
    name: indexrWGgR5ehIYMeta?.name ?? "subscriptions-slug",
    path: indexrWGgR5ehIYMeta?.path ?? "/subscriptions/:slug()",
    meta: indexrWGgR5ehIYMeta || {},
    alias: indexrWGgR5ehIYMeta?.alias || [],
    redirect: indexrWGgR5ehIYMeta?.redirect || undefined,
    component: () => import("/home/app/pages/subscriptions/[slug]/index.vue").then(m => m.default || m)
  },
  {
    name: index400dxCV66iMeta?.name ?? "task_recipe-slug",
    path: index400dxCV66iMeta?.path ?? "/task_recipe/:slug()",
    meta: index400dxCV66iMeta || {},
    alias: index400dxCV66iMeta?.alias || [],
    redirect: index400dxCV66iMeta?.redirect || undefined,
    component: () => import("/home/app/pages/task_recipe/[slug]/index.vue").then(m => m.default || m)
  },
  {
    name: indexvAeuRFDKyXMeta?.name ?? "tasks-slug",
    path: indexvAeuRFDKyXMeta?.path ?? "/tasks/:slug()",
    meta: indexvAeuRFDKyXMeta || {},
    alias: indexvAeuRFDKyXMeta?.alias || [],
    redirect: indexvAeuRFDKyXMeta?.redirect || undefined,
    component: () => import("/home/app/pages/tasks/[slug]/index.vue").then(m => m.default || m)
  },
  {
    name: indexw11IWdxIO5Meta?.name ?? "tasks",
    path: indexw11IWdxIO5Meta?.path ?? "/tasks",
    meta: indexw11IWdxIO5Meta || {},
    alias: indexw11IWdxIO5Meta?.alias || [],
    redirect: indexw11IWdxIO5Meta?.redirect || undefined,
    component: () => import("/home/app/pages/tasks/index.vue").then(m => m.default || m)
  },
  {
    name: index0D2v02tQEyMeta?.name ?? "users-slug",
    path: index0D2v02tQEyMeta?.path ?? "/users/:slug()",
    meta: index0D2v02tQEyMeta || {},
    alias: index0D2v02tQEyMeta?.alias || [],
    redirect: index0D2v02tQEyMeta?.redirect || undefined,
    component: () => import("/home/app/pages/users/[slug]/index.vue").then(m => m.default || m)
  },
  {
    name: indexsn4vQIwrQrMeta?.name ?? "workstation-slug",
    path: indexsn4vQIwrQrMeta?.path ?? "/workstation/:slug()",
    meta: indexsn4vQIwrQrMeta || {},
    alias: indexsn4vQIwrQrMeta?.alias || [],
    redirect: indexsn4vQIwrQrMeta?.redirect || undefined,
    component: () => import("/home/app/pages/workstation/[slug]/index.vue").then(m => m.default || m)
  },
  {
    name: indexvxX2bSdgwzMeta?.name ?? "workstation",
    path: indexvxX2bSdgwzMeta?.path ?? "/workstation",
    meta: indexvxX2bSdgwzMeta || {},
    alias: indexvxX2bSdgwzMeta?.alias || [],
    redirect: indexvxX2bSdgwzMeta?.redirect || undefined,
    component: () => import("/home/app/pages/workstation/index.vue").then(m => m.default || m)
  },
  {
    name: indexHctLeKsi8QMeta?.name ?? "zendesk",
    path: indexHctLeKsi8QMeta?.path ?? "/zendesk",
    meta: indexHctLeKsi8QMeta || {},
    alias: indexHctLeKsi8QMeta?.alias || [],
    redirect: indexHctLeKsi8QMeta?.redirect || undefined,
    component: () => import("/home/app/pages/zendesk/index.vue").then(m => m.default || m)
  }
]