import BaseModel, { EntityType } from './BaseModel'

import { ComplianceOrder as ComplianceOrderInterface } from '~/interfaces/api/Companies.interface'

export default class ComplianceGuard
  extends BaseModel
  implements ComplianceOrderInterface
{
  static entity = EntityType.complianceorder

  static fields() {
    return {
      id: this.number(0),
      created_at: this.string(''),
      updated_at: this.string(''),
      compliance_guard_id: this.attr(''),
      completed_at: this.string(''),
      year: this.attr(''),
      company: this.attr(''),
    }
  }
}
