import BaseModel, { EntityType } from './BaseModel'

import { Card as CardInterface } from '~/interfaces/api/Cards.interface'

export default class Card extends BaseModel implements CardInterface {
  static entity = EntityType.cards

  static fields() {
    return {
      id: this.number(0),
      first_name: this.string(''),
      last_name: this.string(''),
      postal_code: this.string(''),
      card_type: this.string(''),
      last_four: this.string(''),
      object: this.string(''),
      exp_month: this.number(0),
      exp_year: this.number(0),
      primary: this.attr(''),
    }
  }

  // id: number
  // first_name: string
  // last_name: string
  // postal_code: string
  // card_type: string
  // last_four: string
  // exp_month: number
  // exp_year: number
  // primary: Boolean
}
