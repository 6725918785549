import BaseModel, { EntityType } from './BaseModel'

import { TaskRecipe as TaskRecipeInterface } from '~/interfaces/api/TaskRecipe.interface'

export default class TaskRecipe
  extends BaseModel
  implements TaskRecipeInterface
{
  static entity = EntityType.taskRecipe

  static fields() {
    return {
      id: this.number(0),
      name: this.string(''),
      created_at: this.attr(''),
      updated_at: this.attr(''),
      resource_type: this.attr(''),
      description: this.attr(''),
      steps: this.attr([]),
    }
  }
}
