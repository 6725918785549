import BaseModel, { EntityType } from './BaseModel'

import { StripeAlert as StripeAlertInterface } from '~/interfaces/api/StripeAlerts.interface'

export default class StripeAlert
  extends BaseModel
  implements StripeAlertInterface
{
  static entity = EntityType.stripeAlerts

  static fields() {
    return {
      id: this.number(0),
      charge_id: this.number(0),
      refunded: this.boolean(false),
      created_at: this.attr(''),
      user_id: this.number(0),
      user: this.attr(''),
      charge: this.attr(''),
    }
  }

  // id: number
  // charge_id: number
  // refunded: boolean
  // created_at: Date
  // user_id: number
  // user: object
  // charge: object
}
