import BaseModel, { EntityType } from './BaseModel'
import User from './User'

import { ForeignEntity as ForeignEntityInterface } from '~/interfaces/api/ForeignEntity.interface'

export default class ForeignEntity
  extends BaseModel
  implements ForeignEntityInterface
{
  static entity = EntityType.foreignEntities

  static fields() {
    return {
      id: this.number(0),
      name: this.string(''),
      created_at: this.attr(''),
      formation_date: this.attr(''),
      address: this.attr(''),
      entity_type: this.attr(''),
      state: this.attr(''),
      compliance_guard_active: this.boolean(false),
      registered_agent_active: this.boolean(false),
      user_id: this.number(0),
    }
  }
}
