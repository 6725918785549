import Product from './Product'
import BaseModel, { EntityType } from './BaseModel'
import { Form as FormInterface } from '~/interfaces/api/Forms.interface'

export default class Form extends BaseModel implements FormInterface {
  static entity = EntityType.forms

  static fields() {
    return {
      id: this.string(''),
      resource_type: this.string(''),
      created_at: this.attr(''),
      updated_at: this.attr(''),
      name: this.string(''),
      body: this.string(''),
      can_render_to_pdf: this.boolean(false),
      can_render_to_word: this.boolean(false),
      last_render_error: this.string(''),
      product_id: this.number(0),
      product: this.belongsTo(Product, 'product_id'),
    }
  }

  // id!: string
  // resource_type!: string
  // created_at!: Date
  // updated_at!: Date
  // name!: string
  // slug!: string
  // is_v2!: boolean
  // body!: string
  // product_id!: string
  // product: Product
}
