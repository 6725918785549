import BaseModel, { EntityType } from './BaseModel'
import User from './User'
import Company from './Company'

import { File as FilesInterface } from '~/interfaces/api/Files.interface'

export default class File extends BaseModel implements FilesInterface {
  static entity = EntityType.files

  static fields() {
    return {
      id: this.number(0),
      name: this.string(''),
      created_at: this.attr(''),
      updated_at: this.attr(''),
      file_file_name: this.attr(''),
      read: this.attr(''),
      company_id: this.number(0),
      user_id: this.number(0),
      user: this.belongsTo(User, 'user_id'),
      company: this.belongsTo(Company, 'company_id'),
      task_id: this.attr(''),
    }
  }

  // id: number
  // name: string
  // file_file_name: string
  // read: Boolean
  // created_at: Date
  // updated_at: Date
  // file_updated_at: Date
  // company_id: number
  // user_id: number
}
