import BaseModel, { EntityType } from './BaseModel'
import User from './User'

import { Company as CompanyInterface } from '~/interfaces/api/Companies.interface'

export default class Company extends BaseModel implements CompanyInterface {
  static entity = EntityType.companies

  static state() {
    return {
      total: null,
    }
  }

  static fields() {
    return {
      id: this.number(0),
      name: this.string(''),
      created_at: this.attr(''),
      updated_at: this.attr(''),
      formation_date: this.attr(''),
      address: this.attr(''),
      entity_type: this.attr(''),
      state: this.attr(''),
      contact_name: this.attr(''),
      contact_phone: this.attr(''),
      contact_email: this.attr(''),
      agent_name: this.attr(''),
      agent_address: this.attr(''),
      agent_city: this.attr(''),
      agent_state: this.attr(''),
      agent_zip: this.attr(''),
      county: this.attr(''),
      has_formation: this.boolean(false),
      compliance_guard_active: this.boolean(false),
      registered_agent_active: this.boolean(false),
      ein: this.attr(''),
      state_company_id: this.attr(''),
      user_id: this.number(0),
      user: this.belongsTo(User, 'user_id'),
      notes: this.attr(''),
      mergeable_with: this.attr(''),
      foreign_entity_of: this.attr(''),
      possible_foreign_entities: this.attr(''),
      write_protected_attributes: this.attr(''),
    }
  }

  // id!: number
  // name!: string
  // created_at: Date
  // updated_at: Date
  // formation_date: Date
  // address: string
  // entity_type: string
  // state: string
  // contact_name: string
  // contact_phone: number
  // contact_email: string
  // agent_name: string
  // agent_address: string
  // agent_city: string
  // agent_state: string
  // county: string
  // has_formation: boolean
  // compliance_guard_active: boolean
  // registered_agent_active: boolean
  // ein: string
  // state_company_id: string
  // user_id: number
  // notes: Array<{}>
}
