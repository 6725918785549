import BaseModel, { EntityType } from './BaseModel'
import User from './User'

import { Subscription as SubscriptionInterface } from '~/interfaces/api/Subscriptions.interface'

export default class Subscription
  extends BaseModel
  implements SubscriptionInterface
{
  static entity = EntityType.subscriptions

  static fields() {
    return {
      id: this.number(0),
      plan_id: this.number(0),
      billing_info_id: this.attr(''),
      state: this.string(''),
      activated_at: this.attr(''),
      canceled_at: this.attr(''),
      trial_started_at: this.attr(''),
      trial_ends_at: this.attr(''),
      current_period_ends_at: this.attr(''),
      user_id: this.number(0),
      sub_type: this.attr(''),
      next_bill_date: this.attr(''),
      user: this.belongsTo(User, 'user_id'),
      company_id: this.number(0),
    }
  }

  // id: number
  // plan_id: number
  // billing_info_id: string
  // state: string
  // activated_at: Date
  // canceled_at: Date
  // trial_started_at: Date
  // trial_ends_at: Date
  // current_period_ends_at: Date
  // next_bill_date: Date
  // user_id: number
  // sub_type: string
  // company_id: number
}
