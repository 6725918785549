import BaseModel, { EntityType } from './BaseModel'

import { Refund as RefundInterface } from '~/interfaces/api/Refunds.interface'

export default class Refund extends BaseModel implements RefundInterface {
  static entity = EntityType.refunds

  static fields() {
    return {
      id: this.number(0),
      name: this.string(''),
      amount_to_refund: this.string(''),
      amount_refunded: this.string(''),
      created_at: this.attr(''),
      updated_at: this.attr(''),
      refunded_at: this.attr(''),
      admin_id: this.number(0),
      state: this.string(''),
    }
  }

  // id: number
  // name: string
  // amount_to_refund: string
  // amount_refunded: string
  // created_at: Date
  // updated_at: Date
  // refunded_at: Date
  // admin_id: number
  // state: string
}
