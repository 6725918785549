import BaseModel, { EntityType } from "./BaseModel"

import { AdminUser as AdminUsersInterface } from "~/interfaces/api/AdminUsers.interface"

export default class CurrentUser extends BaseModel implements AdminUsersInterface {
  static entity = EntityType.currentUser

  static fields() {
    return {
      id: this.number(0),
      name: this.string(""),
      email: this.string(""),
      created_at: this.attr(""),
      updated_at: this.attr(""),
      active: this.attr(""),
      form_editor: this.attr(""),
      superuser: this.attr(""),
      support: this.attr(""),
      chargeback_editor: this.attr(""),
      task_editor: this.attr(""),
      stats: this.attr("")
    }
  }
}
