import BaseModel, { EntityType } from './BaseModel'

import { Lander as LanderInterface } from '~/interfaces/api/Lander.interface'

export default class Lander extends BaseModel implements LanderInterface {
  static entity = EntityType.lander

  static fields() {
    return {
      id: this.number(0),
      created_at: this.attr(''),
      updated_at: this.attr(''),
      unique_id: this.attr(''),
      resource_type: this.attr(''),
      name: this.string(''),
      slug: this.string(''),
      page_type: this.string(''),
      draft_key: this.string(''),
      product_id: this.number(0),
      last_synced: this.attr(''),
      updated: this.attr(''),
    }
  }
}
