import BaseModel, { EntityType } from './BaseModel'

import { Mail as MailInterface } from '~/interfaces/api/Mail.interface'

export default class Mail extends BaseModel implements MailInterface {
  static entity = EntityType.companyMail

  static fields() {
    return {
      id: this.number(0),
      name: this.string(''),
      state: this.string(''),
      status: this.string(''),
      source: this.string(''),
      type: this.string(''),
      pages: this.number(0),
      created_at: this.attr(''),
      read_at: this.attr(''),
      company_id: this.number(0),
    }
  }

  // id: number
  // name: string
  // file_file_name: string
  // read: Boolean
  // created_at: Date
  // updated_at: Date
  // file_updated_at: Date
  // company_id: number
  // user_id: number
}
