import BaseModel, { EntityType } from './BaseModel'
import Product from './Product'

import {
  Product as ProductInterface,
  ProductVersion as ProductVersionInterface,
} from '~/interfaces/api/Products.interface'

export default class ProductVersion
  extends BaseModel
  implements ProductVersionInterface
{
  static entity = EntityType.productVersions

  static fields() {
    return {
      id: this.number(0),
      resource_type: this.string(''),
      created_at: this.attr(''),
      updated_at: this.attr(''),
      slug: this.string(''),
      status: this.string(''),
      is_default: this.boolean(false),
      product_id: this.number(0),
      product: this.belongsTo(Product, 'product_id'),
      form_id: this.number(0),
      form_name: this.string(''),
      payload_id: this.number(0),
      payload_name: this.string(''),
      form_is_v2: this.boolean(false),
      is_previewable: this.boolean(false),
      note: this.string(''),
    }
  }

  get test_url() {
    return `https://www.legalnature.com/${this.product?.url_for_order_type}/create/${this.product?.slug}/${this.slug}`
  }

  // id!: number
  // resource_type!: string
  // created_at!: Date
  // updated_at!: Date
  // slug!: string
  // status!: 'active' | 'archived'
  // is_default!: boolean
  // product_id!: number
  // product: ProductInterface
  // form_id!: number
  // form_name!: any
  // payload_id!: number
  // payload_name!: any
  // form_is_v2!: boolean
  // is_previewable!: boolean
  // note: string
}
