import BaseModel, { EntityType } from './BaseModel'

import { ComplianceItem as ComplianceItemInterface } from '~/interfaces/api/Companies.interface'

export default class ComplianceItem
  extends BaseModel
  implements ComplianceItemInterface
{
  static entity = EntityType.complianceitem

  static fields() {
    return {
      id: this.number(0),
      created_at: this.attr(''),
      updated_at: this.attr(''),
      compliance_guard_id: this.attr(''),
      name: this.attr(''),
      starts_at: this.attr(''),
      due_at: this.attr(''),
      status: this.attr(''),
      resource_type: this.attr(''),
      company: this.attr(''),
      fee: this.attr(''),
      fee_description: this.attr(''),
      paid_at: this.attr(''),
    }
  }
}
