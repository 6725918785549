import BaseModel, { EntityType } from './BaseModel'

import { StateInfo as StateInfoInterface } from '~/interfaces/api/StateInfo.interface'

export default class StateInfo extends BaseModel implements StateInfoInterface {
  static entity = EntityType.stateInfo

  static fields() {
    return {
      id: this.number(0),
      created_at: this.attr(''),
      updated_at: this.attr(''),
      state: this.attr(''),
      state_id: this.number(0),
      llc_cost: this.number(0),
      s_corp_cost: this.number(0),
      c_corp_cost: this.number(0),
      nonprofit_cost: this.number(0),
      corp_expedited: this.attr(''),
      nonprofit_expedited: this.attr(''),
      llc_expedited: this.attr(''),
      certified_copy: this.attr(''),
      certificate_of_good_standing: this.attr(''),
      foreign_entity: this.attr(''),
      articles_of_amendment: this.attr(''),
      articles_of_dissolution: this.attr(''),
      children: this.attr(''),
      type: this.attr(''),
    }
  }

  // id: number
  // created_at: Date
  // updated_at: Date
  // state: Object
  // state_id: number
  // llc_cost: number
  // s_corp_cost: number
  // c_corp_cost: number
  // nonprofit_cost: number
  // corp_expedited: Object
  // nonprofit_expedited: Object
  // llc_expedited: Object
  // certified_copy: {
  //   corp: string
  //   corp_expedited: string
  //   id: number
  //   llc: string
  //   llc_expedited: string
  //   nonprofit: string
  //   nonprofit_expedited: string
  //   type: string
  // }

  // certificate_of_good_standing: {
  //   corp: string
  //   corp_expedited: string
  //   id: number
  //   llc: string
  //   llc_expedited: string
  //   nonprofit: string
  //   nonprofit_expedited: string
  //   type: string
  // }

  // foreign_entity: {
  //   corp: string
  //   corp_expedited: string
  //   id: number
  //   llc: string
  //   llc_expedited: string
  //   nonprofit: string
  //   nonprofit_expedited: string
  //   type: string
  // }

  // articles_of_amendment: {
  //   corp: string
  //   corp_expedited: string
  //   id: number
  //   llc: string
  //   llc_expedited: string
  //   nonprofit: string
  //   nonprofit_expedited: string
  //   type: string
  // }

  // articles_of_dissolution: {
  //   corp: string
  //   corp_expedited: string
  //   id: number
  //   llc: string
  //   llc_expedited: string
  //   nonprofit: string
  //   nonprofit_expedited: string
  //   type: string
  // }

  // children: Array<Object>
  // type: string
}
