import BaseModel, { EntityType } from './BaseModel'
import User from './User'
import Company from './Company'

import { Document as Documentsnterface } from '~/interfaces/api/Documents.interface'

export default class Document extends BaseModel implements Documentsnterface {
  static entity = EntityType.documents

  static fields() {
    return {
      id: this.number(0),
      name: this.string(''),
      created_at: this.attr(''),
      updated_at: this.attr(''),
      purchased_at: this.attr(''),
      purchased_ip: this.attr(''),
      company_id: this.number(0),
      user_id: this.number(0),
      user: this.belongsTo(User, 'user_id'),
      company: this.belongsTo(Company, 'company_id'),
    }
  }

  // id: number
  // name: string
  // created_at: Date
  // updated_at: Date
  // purchased_at: Date
  // purchased_ip: string
  // company_id: number
  // user_id: number
}
