import BaseModel, { EntityType } from './BaseModel'

import { ComplianceGuard as ComplianceGuardInterface } from '~/interfaces/api/Companies.interface'

export default class ComplianceGuard
  extends BaseModel
  implements ComplianceGuardInterface
{
  static entity = EntityType.complianceguard

  static fields() {
    return {
      id: this.number(0),
      activated_at: this.string(''),
      available_amendments: this.attr(''),
      canceled_at: this.attr(''),
      company_id: this.attr(''),
      compliance_items: this.attr(''),
      compliance_orders: this.attr(''),
      created_at: this.attr(''),
      recurly_subscription_id: this.attr(''),
      resource_type: this.attr(''),
      updated_at: this.attr(''),
      user_id: this.attr(''),
    }
  }
}
