import format from 'date-fns/format'
import BaseModel, { EntityType } from './BaseModel'
import Order from './Order'
import Charge from './Charge'
import Document from './Document'
import Company from './Company'
import Session from './Session'

import { User as UserInterface } from '~/interfaces/api/Users.interface'

export default class User extends BaseModel implements UserInterface {
  static entity = EntityType.users

  static fields() {
    return {
      id: this.number(0),
      name: this.string(''),
      email: this.string(''),
      phone: this.number(0),
      orders: this.hasMany(Order, 'user_id'),
      charges: this.hasMany(Charge, 'user_id'),
      documents: this.hasMany(Document, 'user_id'),
      companies: this.hasMany(Company, 'user_id'),
      sessions: this.hasMany(Session, 'user_id'),
      notes: this.attr(''),
      plus_active: this.boolean(false),
      plus_active_until: this.attr(''),
      editor_controls: this.boolean(false),
      pricing_set: this.attr(''),
      revenue: this.number(0),
    }
  }

  // id!: number
  // name!: string
  // email!: string
  // phone: number
  // revenue: number
  // orders?: []
  // charges?: []
  // documents?: []
  // companies?: []
  // sessions?: []
  // notes: Array<{}>
  // plus_active: boolean
  // editor_controls: boolean
  // plus_active_until: Date
  // pricing_set: {
  //   compliance_guard_plan_id: number
  //   monthly_plan_id: number
  //   registered_agent_plan_id: number
  //   annual_plan_id: number
  // }

  get plus_active_until_Mdyhm() {
    if (this.plus_active_until) {
      const date = new Date(this.plus_active_until)
      return format(date, 'MM/dd/yyyy HH:mm')
    }
  }
}
