import BaseModel, { EntityType } from './BaseModel'

import { Team as TeamInterface } from '~/interfaces/api/Team.interface'

export default class Team extends BaseModel implements TeamInterface {
  static entity = EntityType.teams

  static fields() {
    return {
      id: this.number(0),
      name: this.string(''),
      created_at: this.attr(''),
      updated_at: this.attr(''),
      resource_type: this.attr(''),
    }
  }
}
