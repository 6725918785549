import BaseModel, { EntityType } from "./BaseModel"

import { TasksCounter as TasksCounterInterface } from "~/interfaces/api/TasksCounter.interface"

export default class TasksCounter extends BaseModel implements TasksCounterInterface {
  static entity = EntityType.tasksCounter

  static fields() {
    return {
      id: this.uid(),
      count: this.number(0)
    }
  }
}
