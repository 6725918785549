import BaseModel, { EntityType } from "./BaseModel"

import { AdminUser as AdminUsersInterface } from "~/interfaces/api/AdminUsers.interface"

export default class AdminUser extends BaseModel implements AdminUsersInterface {
  static entity = EntityType.adminUsers

  static fields() {
    return {
      id: this.number(0),
      name: this.string(""),
      email: this.string(""),
      created_at: this.attr(""),
      updated_at: this.attr(""),
      active: this.attr(""),
      form_editor: this.attr(""),
      order_editor: this.attr(""),
      superuser: this.attr(""),
      support: this.attr(""),
      chargeback_editor: this.attr(""),
      task_editor: this.attr(""),
      editor_controls: this.attr(""),
      stats: this.attr(""),
      handle: this.attr(""),
      team_assignments: this.attr("")
    }
  }
}
