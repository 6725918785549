import BaseModel, { EntityType } from './BaseModel'

import { Stats as StatsInterface } from '~/interfaces/api/Stats.interface'

export default class Stats extends BaseModel implements StatsInterface {
  static entity = EntityType.stats

  static fields() {
    return {
      id: this.number(0),
      date: this.string(''),
      source: this.attr(''),
      campaign: this.attr(''),
      cost: this.attr(''),
      score_margin: this.attr(''),
      revenue: this.attr(''),
      return_rate: this.attr(''),
      emails: this.number(0),
      subscriptions: this.number(0),
      canceled: this.number(0),
      sales: this.number(0),
      bf_sales: this.number(0),
      cpa: this.attr(''),
    }
  }

  // id: number
  // date: string
  // source: string
  // campaign: string
  // cost: string
  // score_margin: string
  // revenue: string
  // return_rate: string
  // emails: number
  // subscriptions: number
  // canceled: number
  // sales: number
  // bf_sales: number
  // cpa: number
}
