import BaseModel, { EntityType } from './BaseModel'

import { Notification as NotificationInterface } from '~/interfaces/api/Notification.interface'

export default class Notification
  extends BaseModel
  implements NotificationInterface
{
  static entity = EntityType.notification

  static state() {
    return {
      total: null,
    }
  }

  static fields() {
    return {
      id: this.number(0),
      created_at: this.string(''),
      updated_at: this.string(''),
      title: this.string(''),
      notifiable_type: this.string(''),
      notifiable_id: this.number(0),
      read_at: this.string(''),
      sub_type: this.string(''),
      sub_id: this.number(0),
    }
  }
}
