import format from 'date-fns/format'
import BaseModel, { EntityType } from './BaseModel'
import Product from './Product'

import {
  Experiment as ExperimentInterface,
  ExperimentSummary,
  Variant,
} from '~/interfaces/api/Experiments.interface'
import { Product as ProductInterface } from '~/interfaces/api/Products.interface'

export default class Experiment
  extends BaseModel
  implements ExperimentInterface
{
  static entity = EntityType.experiments

  static fields() {
    return {
      id: this.number(0),
      resource_type: this.string(''),
      created_at: this.attr(''),
      updated_at: this.attr(''),
      name: this.string(''),
      description: this.string(''),
      conclusion: this.string(''),
      variant_type: this.string(''),
      variants: this.attr([]),
      started_at: this.attr(''),
      is_active: this.boolean(false),
      completed_at: this.string(''),
      product_id: this.number(0),
      product: this.belongsTo(Product, 'product_id'),
      product_name: this.string(''),
      product_order_type: this.string(''),
      stats: this.attr(''),
    }
  }

  get completed_at_Mdy() {
    return format(new Date(this.completed_at), 'MM/dd/yyyy')
  }

  // id!: number
  // resource_type!: string
  // created_at!: Date
  // updated_at!: Date
  // name!: string
  // description?: any
  // variant_type!: string
  // variants!: Variant[]
  // started_at?: any
  // completed_at?: any
  // is_active!: boolean
  // product_id!: number
  // product: ProductInterface
  // product_name: string
  // product_order_type: string
  // stats: {
  //   chart_data: {
  //     [key: string]: Array<{
  //       name: string
  //       data: Array<number>
  //     }>
  //   }
  //   x_axis: Array<string>
  //   summary: ExperimentSummary[]
  // }
}
