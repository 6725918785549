import BaseModel, { EntityType } from './BaseModel'

import { Plan as PlanInterface } from '~/interfaces/api/Plans.interface'

export default class Plan extends BaseModel implements PlanInterface {
  static entity = EntityType.plans

  static fields() {
    return {
      id: this.number(0),
      sub_type: this.string(''),
      code: this.string(''),
      plan_id: this.number(0),
      created_at: this.attr(''),
      updated_at: this.attr(''),
      interval: this.string(''),
      interval_count: this.number(0),
      cost: this.number(0),
      trial_period: this.number(0),
      name: this.attr(''),
      state: this.attr(''),
    }
  }

  // id: number
  // sub_type: string
  // created_at: Date
  // updated_at: Date
  // code: string
  // interval: string
  // interval_count: number
  // trial_period: number
  // cost: number
  // name: string

  get cost_formatted() {
    return '$' + this.cost.toFixed(2)
  }
}
