import BaseModel, { EntityType } from './BaseModel'
import User from './User'

import { Note as NoteInterface } from '~/interfaces/api/Notes.interface'

export default class Note extends BaseModel implements NoteInterface {
  static entity = EntityType.notes

  static fields() {
    return {
      id: this.number(0),
      admin_id: this.number(0),
      created_at: this.attr(''),
      updated_at: this.attr(''),
      admin: this.attr(''),
      notable_type: this.attr(''),
      notable_id: this.number(0),
      user_id: this.number(0),
      body: this.attr(''),
      user: this.belongsTo(User, 'user_id'),
    }
  }

  // id: number
  // admin_id: number
  // created_at: Date
  // updated_at: Date
  // admin: object
  // notable_type: string
  // notable_id: number
  // user_id: number
  // body: string
}
