import { formatInTimeZone } from 'date-fns-tz/esm'
import BaseModel, { EntityType } from './BaseModel'
import { Chargeback as ChargebacksInterface } from '~/interfaces/api/Chargebacks.interface'

export default class Chargeback
  extends BaseModel
  implements ChargebacksInterface
{
  static entity = EntityType.chargebacks

  static fields() {
    return {
      id: this.number(0),
      charge_id: this.number(0),
      created_at: this.attr(''),
      updated_at: this.attr(''),
      due_at: this.attr(''),
      user_id: this.number(0),
      user: this.attr(''),
      charge: this.attr(''),
      status: this.attr(''),
      processor: this.attr(''),
      amount: this.number(0),
      processor_url: this.attr(''),
      evidence: this.attr(''),
    }
  }

  // id: number
  // charge_id: number
  // created_at: Date
  // updated_at: Date
  // completed_at: Date
  // due_at: Date
  // user_id: number
  // user: object
  // charge: object
  // status: string
  // processor: string
  // amount: number
  // processor_url: string
  // evidence: {
  //   product_description: string
  //   billing_address: string
  //   access_activity_log: number
  //   customer_name: string
  //   customer_email_address: string
  //   customer_purchase_ip: string
  // }

  get amount_formatted() {
    if (this.amount) return '$' + this.amount.toFixed(2)
  }

  get due_at_Mdyhm() {
    if (this.due_at) {
      const due_at_obj = new Date(this.due_at)
      return formatInTimeZone(
        due_at_obj,
        'America/Los_Angeles',
        'MM/dd/yyyy HH:mm'
      )
    }
  }
}
