export const appHead = {"meta":[{"charset":"utf-8"},{"content":"width=device-width, initial-scale=1","name":"viewport"},{"content":"","hid":"description","name":"description"}],"link":[{"href":"/apple-touch-icon.png","rel":"apple-touch-icon","type":"image/png"},{"href":"/favicon.ico","rel":"icon","type":"image/x-icon"}],"style":[],"script":[{"async":true,"src":"https://js.stripe.com/v3/"}],"noscript":[],"htmlAttrs":{"lang":"en"},"title":"LegalNature Admin"}

export const appBaseURL = "/"

export const appBuildAssetsDir = "/_nuxt/"

export const appCdnURL = ""

export const appLayoutTransition = false

export const appPageTransition = false

export const appKeepalive = false

export const appRootId = "__nuxt"

export const appRootTag = "div"

export const renderJsonPayloads = true

export const componentIslands = false

export const remoteComponentIslands = false

export const devPagesDir = null

export const devRootDir = null

export const vueAppRootContainer = '#__nuxt'