import BaseModel, { EntityType } from './BaseModel'
import User from './User'

import { Session as SessionsInterface } from '~/interfaces/api/Sessions.interface'

export default class Session extends BaseModel implements SessionsInterface {
  static entity = EntityType.sessions

  static fields() {
    return {
      id: this.number(0),
      created_at: this.attr(''),
      url: this.attr(''),
      user_id: this.number(0),
      user: this.belongsTo(User, 'user_id'),
    }
  }

  // id: number
  // created_at: Date
  // url: string
  // user_id: number
}
